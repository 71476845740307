import React from "react";

export function Footer({ title = "" }) {
    return (
        <footer>
            <a href="http://lukyanchuk.com/contact" target="_blank" rel="noreferrer">
                <img src="https://lukyanchuk.com/assets/logo/logo.png" alt="logo" width={50}/>
            </a>
            <a
                href="http://lukyanchuk.com/contact"
                target="_blank"
                rel="noreferrer"
                style={{margin: 20}}
            >
                <code>{title}</code>
            </a>
            <a
                href="http://lukyanchuk.com/"
                target="_blank"
                rel="noreferrer"
                style={{margin: 20}}
            >
                <code>Yaroslav Lukyanchuk</code>
            </a>
            <a
                href="http://lukyanchuk.com/contact"
                target="_blank"
                rel="noreferrer"
                style={{margin: 20}}
            >
                <code>Contact</code>
            </a>
        </footer>
    );
}
